import * as React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
const { Autocomplete, SearchInput, Text, Icon, toaster } = require('evergreen-ui')

import { colors } from '../../utils/colors'

interface IState {
  searchText: string
}

enum searchTypesEnum {
  clientId = 'Client ID',
  bankCode = 'Bank Code',
  filename = 'File Name',
  subtype = 'SubType',
  bank_subtype = 'BankSubtype',
  taskId = 'Stashboard Task ID',
  stashboardJobId = 'Stashboard Job Id',
  parentUserId = 'Parent User Id',
}

const ITEM_HEIGHT = 48

class SearchContainer extends React.Component<{}, IState> {
  public state = {
    searchText: '',
  }

  public render() {
    return (
      <Container>
        <Autocomplete
          onChange={this.search}
          items={Object.keys(searchTypesEnum)}
          itemSize={ITEM_HEIGHT}
          renderItem={this.renderItem}
          isFilterDisabled={true}
          children={(props: any) => {
            const { getInputProps, getRef, inputValue } = props
            return (
              <SearchInput
                placeholder="Search"
                innerRef={getRef}
                value={inputValue}
                {...getInputProps()}
                onChange={(e: any) => {
                  this.handleSearchText(e)
                  getInputProps().onChange(e)
                }}
              />
            )
          }}
        />
      </Container>
    )
  }

  private handleSearchText(e: any) {
    this.setState({ searchText: e.target.value.toUpperCase() })
  }

  private renderItem = (item: any) => {
    const { searchText } = this.state
    if (!searchText) {
      return null
    }
    return (
      <SearchItem
        key={item.key}
        isSelected={item.isHighlighted}
        onClick={() => this.search(item.key)}
      >
        <Text className={'search-text'}>{searchText}</Text>
        <div className="search-type">
          <Text color={'muted'} size={300} marginRight={4}>
            In {searchTypesEnum[item.key as keyof typeof searchTypesEnum]}
          </Text>
          <Icon icon={'key-enter'} color={'muted'} size={12} marginTop={2} />
        </div>
      </SearchItem>
    )
  }

  private search = (item: keyof typeof searchTypesEnum) => {
    if (searchTypesEnum[item] === searchTypesEnum.clientId) {
      navigate(`/search/client/${this.state.searchText}`)
    }
    if (searchTypesEnum[item] === searchTypesEnum.bankCode) {
      navigate(`/search/bank/${this.state.searchText}`)
    }
    if (searchTypesEnum[item] === searchTypesEnum.filename) {
      navigate(`/search/fileName/${this.state.searchText}`)
    }
    if (searchTypesEnum[item] === searchTypesEnum.bank_subtype) {
      const regex = /^([A-Za-z0-9]+_[a-zA-Z0-9_&]+)$/
      const { searchText } = this.state
      if (regex.test(searchText)) {
        navigate(`/search/banksubtype/${this.state.searchText}`)
      } else {
        toaster.danger('Please enter Banksubtype in correct format')
      }
    }
    if (searchTypesEnum[item] === searchTypesEnum.subtype) {
      navigate(`/search/subtype/${this.state.searchText}`)
    }
    if (searchTypesEnum[item] === searchTypesEnum.taskId) {
      navigate(`/search/taskId/${this.state.searchText}`)
    }
    if (searchTypesEnum[item] === searchTypesEnum.stashboardJobId) {
      navigate(`/search/stashboardJobId/${this.state.searchText}`)
    }
    if (searchTypesEnum[item] === searchTypesEnum.parentUserId) {
      navigate(`/search/parentUserId/${this.state.searchText}`)
    }
    this.resetSearchState()
  }

  private resetSearchState = () => this.setState({ searchText: '' })
}

const Container = styled.div`
  width: 300px;
`

const SearchItem: any = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: ${ITEM_HEIGHT}px;
  padding: 0 8px 0 8px;
  justify-content: space-between;
  cursor: pointer;
  background: ${({ isSelected }: any) => (isSelected ? colors.hoverBackground : 'none')};
  :hover {
    background: ${colors.hoverBackground};
  }
  .search-type {
    display: flex;
    align-items: center;
    padding: 2px 4px;
    border-radius: 5px;
    margin: 0 4px;
    background: rgba(0, 0, 0, 0.01);
    border: solid 1px lightgrey;
    flex-shrink: 0;
  }
  .search-text {
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
  }
`

export default SearchContainer
