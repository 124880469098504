import * as React from 'react'
import styled from 'styled-components'
import { FilterStatus } from '../../schemaTypes'
import { getFileStatusStripeColor } from '../../utils/colors'
import { AllFilterEnum, FilterStatusEnum } from './FilterStatusEnum'

const { Popover, Position, Menu, Text, Button } = require('evergreen-ui')

export type fileStatusFilterType = FilterStatus | AllFilterEnum

interface IProps {
  onFilterChange: (fileStatus: fileStatusFilterType) => void
}

interface IState {
  fileStatus: fileStatusFilterType
}

class FileStatusFilter extends React.Component<IProps, IState> {
  public state = {
    fileStatus: AllFilterEnum.ALL,
  }

  public render() {
    const fileStatuses: any = Object.keys(FilterStatusEnum)
    return (
      <Popover
        position={Position.BOTTOM_RIGHT}
        content={({ close }: { close: () => void }) => (
          <MenuContainer>
            <Menu.Group>
              {fileStatuses.map((key: any) => (
                <Item
                  onClick={() => {
                    this.setState({ fileStatus: key }, () => {
                      this.props.onFilterChange(this.state.fileStatus)
                      close()
                    })
                  }}
                  key={key}
                  status={key}
                >
                  <Text style={{ fontWeight: this.isSelected(key) ? 600 : 400 }}>
                    {
                      //@ts-ignore
                      FilterStatusEnum[key]
                    }
                  </Text>
                </Item>
              ))}
            </Menu.Group>
          </MenuContainer>
        )}
      >
        <Button iconAfter="chevron-down">
          <span>Filter </span>
          <FilterValue>{FilterStatusEnum[this.state.fileStatus]}</FilterValue>
        </Button>
      </Popover>
    )
  }

  private isSelected = (key: fileStatusFilterType) => {
    return key === this.state.fileStatus
  }
}

const FilterValue = styled.span`
  height: 29px;
  text-align: right;
  line-height: 29px;
  border-left: 1px solid rgba(66, 90, 112, 0.2);
  width: 9rem;
  margin-left: 16px;
`

const Item: any = styled.div`
  cursor: pointer;
  padding: 8px 4px;
  :hover {
    background: rgba(14, 30, 37, 0.05);
  }
  ::before {
    margin: 0 16px;
    content: '';
    font-size: 4px;
    text-align: center;
    display: inline-block;
    width: 12px;
    height: 12px;
    background: ${({ status }: any) => getFileStatusStripeColor(status)};
    border-radius: 50%;
  }
`

const MenuContainer = styled.div``

export default FileStatusFilter
