import * as React from 'react'
import { tkheadersBoundsDownload, tkheadersBoundsCore } from '../../api/tkheaders-header-bound'
import WithAuthorization from '../WithAuthorization/WithAuthorization'
const { Button, TextInput, Dialog, Paragraph } = require('evergreen-ui')
import { navigate } from '@reach/router'
import { escapeRegExp } from '../../utils/commonUtils'
import TkHeadersLog from './tkHeadersLog'
import ErrorLog from './errorLog'
import { PreviewExtraction, IPropsDetail } from '../../components/NewConfig/PreviewExtraction'
function draw(ctx: any, location: any, height: number) {
  ctx.beginPath()
  ctx.moveTo(location.x, 0)
  ctx.lineTo(location.x, height)
  ctx.strokeStyle = 'red'
  ctx.stroke()
}
function usePersistentState(init: any[]) {
  React.useEffect(() => {
    localStorage.setItem('draw-app', JSON.stringify(value))
  })
  const [value, setValue] = React.useState(JSON.parse(localStorage.getItem('draw-app')!) || init)
  return [value, setValue]
}
function usePersistentCanvas(image: any, headers: any, setOfsetLeft: any) {
  const url = image['image']
  const [locations, setLocations] = usePersistentState([])
  const canvasRef = React.createRef<HTMLCanvasElement>()
  React.useEffect(() => {
    const canvas = canvasRef.current as HTMLCanvasElement
    setOfsetLeft(canvas.offsetLeft + 30)
    let ctx: CanvasRenderingContext2D = canvas.getContext('2d')!
    ctx.clearRect(0, 0, image['width'], image['height'])
    var img = new Image()
    img.src = `data:image/jpg;base64,${url}`
    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      locations.forEach((location: any) => draw(ctx, location, image['height']))
    }
  })
  return [locations, setLocations, canvasRef]
}
function ImageProcessing(props: any) {
  const {
    headers,
    image,
    config_input,
    bank_name,
    log,
    error,
    input_pdf,
    page,
    params
  } = props.location.state
  if (headers && image && config_input && bank_name && log && input_pdf && page) {
    const [isLoader, setLoader] = React.useState(false)
    const [isPreviewLoader, setPreviewLoader] = React.useState(false)
    const [ofsetLeft, setOfsetLeft] = React.useState(0)
    const [lineNumber, setLineNumber] = React.useState(-1)
    const [isShown, setisShown] = React.useState(false)
    const [isError, setisError] = React.useState(false)
    const [locations, setLocations, canvasRef] = usePersistentCanvas(image, headers, setOfsetLeft)
    const [previewExtractionParams, setPreviewExtractionParams] = React.useState<IPropsDetail>({
      popupTitle: "EditConfig Preview",
      popupName: "EditConfigPreview",
      zipResponse: null,
      zipResponseTimestamp: (new Date()),
      onUnloadPopup: () => void 0,
      inputHeaders: null,
      configFilenameRegex: "config.xlsx",
      extractFilenameRegex: "output.xlsx",
      logFilenameRegex: "execution.log",
    })


    const handleCanvasClick = (e: any) => {
      e.preventDefault()
      const newLocation = { x: window.pageXOffset + e.clientX - ofsetLeft, y: 0 }
      setLocations([...locations, newLocation])
    }
    const handleClear = (e: any) => {
      e.preventDefault()
      setLocations([])
    }
    const handleUndo = (e: any) => {
      e.preventDefault()
      setLocations(locations.slice(0, -1))
    }
    const handelLineChange = (e: any) => {
      e.preventDefault()
      setisError(false)
      const regex = /^[0-9]+$/
      if (regex.test(e.target.value)) {
        setLineNumber(e.target.value - 1)
      }
    }
    const displayLineNo = () => {
      return (
        locations.map((item: any, index: number) => {
          const value = item.x - (index * 9)
          return (
            <div style={{ position: 'relative', left: `${value}px` }}>
              {index + 1}
            </div>
          )
        }))
    }
    const sortingArray = (sottedArray: any[]) => {
      sottedArray.sort((a: any, b: any) => {
        if (a['x'] > b['x']) {
          return 1
        } else if (a['x'] < b['x']) {
          return -1
        } else {
          return 0
        }
      })
      return sottedArray
    }
    const onConfirmDelete = () => {
      if (lineNumber >= 0 && lineNumber < locations.length) {
        const sottedArray = sortingArray([...locations])
        const item = sottedArray[lineNumber]
        const indexOf = locations.indexOf(item)
        const tempArray = [...locations]
        setisShown(false)
        tempArray.splice(indexOf, 1)
        setLocations(tempArray)
      } else {
        setisError(true)
      }
    }
    const deleteByLine = () => {
      return (
        <section>
          <Dialog
            isShown={isShown}
            title="Delete by line"
            onConfirm={onConfirmDelete}
            onCloseComplete={() => setisShown(false)}
            confirmLabel="Ok"
          >
            <section style={{ padding: '20px' }}>
              <TextInput name="line" placeholder="Enter line no..." onChange={handelLineChange} />
              {isError ? (
                <Paragraph color={'red'} size={200} marginTop="10px">
                  Enter line number within Range{' '}
                </Paragraph>
              ) : null}
              <Paragraph size={100} marginTop="10px">
                <b>*</b> Lines start from 1
              </Paragraph>
              <Paragraph size={100} marginTop="10ox">
                <b>**</b> Enter line number less than {locations.length}
              </Paragraph>
            </section>
          </Dialog>
        </section>
      )
    }
    const displayPopup = () => {
      if (previewExtractionParams && previewExtractionParams.zipResponse) {
        return (<PreviewExtraction params={previewExtractionParams} ></PreviewExtraction>)
      }
      else {
        return null
      }
    }


    const handleDelete = (e: any) => {
      e.preventDefault()
      setisShown(true)
    }

    const collectBounds = () => {
      let bounds: any[] = []
      const boundArray = sortingArray([...locations])
      boundArray.forEach((element: any, index: any) => {
        let coordinates: any[] = []
        let boundData: any = []
        coordinates.push(element['x'])
        coordinates.push(element['y'])
        boundData.push(index)
        boundData.push(coordinates)
        bounds.push(boundData)
      })
      return bounds
    }
    const handleSubmit = () => {
      setLoader(true)
      const bounds = collectBounds()
      tkheadersBoundsDownload(
        bank_name,
        config_input,
        input_pdf,
        page,
        headers['bounds_len'],
        headers['scale_factor'],
        headers['split_point'],
        bounds,
        headers['header_index'],
        headers['header_origin'],
        params
      )
        .then((result: any) => {
          localStorage.removeItem('draw-app')
          navigate('/')
        })
        .catch((error: any) => {
          setLoader(false)
          alert('unable to download file')
        })
    }
    const handlePreviewSubmit = () => {
      setPreviewLoader(true)
      const bounds = collectBounds()

      tkheadersBoundsCore(
        bank_name,
        config_input,
        input_pdf,
        page,
        headers['bounds_len'],
        headers['scale_factor'],
        headers['split_point'],
        bounds,
        headers['header_index'],
        headers['header_origin'],
        params,
        true,
      )
        .then((data: any) => {

          if (previewExtractionParams.zipResponse) {
            //close popup and reopen to make sure it is not hidden
            setPreviewExtractionParams(previousState => ({
              ...previousState,
              zipResponse: null
            }))
          }


          setPreviewExtractionParams(previousState => ({
            ...previousState,
            zipResponse: data.fileBlob,
            zipResponseTimestamp: (new Date()),
            onUnloadPopup: onUnloadPopup,
            inputHeaders: headers,
            configFilenameRegex: escapeRegExp(config_input.name),
            extractFilenameRegex: (".+_" + bank_name + "_PROCESSED.xlsx"),
            logFilenameRegex: "execution.log",
            selectedConfigSheet: bank_name
          }))

        })
        .catch((error: any) => {
          alert('unable to generate preview')
        }).finally(() => {
          setPreviewLoader(false)
        })

    }

    const onUnloadPopup = () => {

      setPreviewExtractionParams(previousState => ({
        ...previousState,
        zipResponse: null,
        zipResponseTimestamp: null,
        inputHeaders: null,
      }))
    }


    return (
      <>
        <section>
          <div style={{ display: 'flex' }}>
            <Button onClick={handleClear} margin={16}>
              Clear
            </Button>
            <Button onClick={handleUndo} margin={16}>
              Undo
            </Button>
            <Button onClick={handleDelete} margin={16}>
              Delete By Line
            </Button>
            {deleteByLine()}
            <Button onClick={handleSubmit} margin={16} appearance="primary" isLoading={isLoader}>
              Submit
            </Button>
            <Button onClick={handlePreviewSubmit} margin={16} appearance="primary" isLoading={isPreviewLoader}>
              Preview Changes
            </Button>
          </div>
          <div style={{ marginBottom: '10px', marginTop: '20px', display: 'flex' }}>
            {displayLineNo()}
          </div>
          <canvas
            ref={canvasRef}
            width={image['width']}
            height={image['height']}
            onClick={handleCanvasClick}
            style={{ cursor: 'pointer' }}
          />
        </section>
        <div style={{ marginTop: '20px', width: image['width'] }}>
          <TkHeadersLog log={log} />
        </div>
        {displayPopup()}
      </>
    )
  } else {
    return (
      <>
        <ErrorLog error={error} />
        <div style={{ marginTop: '20px' }}>
          <TkHeadersLog log={log} />
        </div>
      </>
    )
  }
}
export default WithAuthorization(ImageProcessing)
