import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
const { Button } = require('evergreen-ui')
import { Link } from '@reach/router'
import { FilesStatus, BankStatus } from '../../schemaTypes'
import FileStatusFilter, {
  fileStatusFilterType,
} from '../../components/FileStatusFilter/FileStatusFilter'
import { AllFilterEnum } from '../../components/FileStatus/file-status.utils'
import SearchContainer from '../../components/SearchContainer/SearchContainer'
import AllFiles from '../../components/AllFiles/AllFiles'
import WithAuthorization from '../../components/WithAuthorization/WithAuthorization'
import CustomCard from '../../components/CustomCard/CustomCard'
import UploadNewFile from '../../components/UploadNewFile/UploadNewFile'
import BulkOperation from '../../components/BulkOperation/BulkOperation'

interface IState {
  filterFileStatus: FilesStatus | undefined
  filterBankStatus: BankStatus | undefined
  bulkDownloadData: any[]
  checked: boolean
  isShowPdf: boolean
  isPdf: boolean
  isExcel: boolean
}

class FilesPage extends React.Component<RouteComponentProps, any> {
  public state = {
    filterFileStatus: undefined,
    filterBankStatus: undefined,
    downloadType: undefined,
    bulkDownloadData: [],
    checked: true,
    isShowPdf: true,
    isPdf: true,
    isExcel: true,
  }

  public render() {
    return (
      <main>
        <div>
          <CardHeader>
            <SearchContainer />

            <BulkOperation />
            <UploadNewFile />
            <Link to={`/files/multipleupload`} style={{ textDecoration: 'none' }}>
              <Button appearance="primary" style={{ marginRight: 10 }}>
                Multiple Upload
              </Button>
            </Link>
            <FileStatusFilter onFilterChange={this.filter} />
          </CardHeader>
          <AllFiles
            fileStatus={this.state.filterFileStatus}
            bankStatus={this.state.filterBankStatus}
          />
        </div>
      </main>
    )
  }

  private filter = (fileStatus: fileStatusFilterType) => {
    if (fileStatus === AllFilterEnum.ALL) {
      return this.setState({
        filterFileStatus: undefined,
        filterBankStatus: undefined,
      })
    } else if (
      fileStatus === 'BS_SUB' ||
      fileStatus === 'BS_RUN' ||
      fileStatus === 'BS_NR' ||
      fileStatus === 'BS_PEN' ||
      fileStatus === 'BS_BNK'
    ) {
      return this.setState({
        filterBankStatus: fileStatus,
      })
    } else {
      return this.setState({ filterFileStatus: fileStatus })
    }
  }
}

const CardHeader = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`

export default WithAuthorization(FilesPage)
