import gql from 'graphql-tag'
import * as React from 'react'
import { Query } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { FilterStatus, GetAllFiles_allFiles_edges } from '../../schemaTypes'
import { generateGenericFavicon } from '../../utils/generateGenericFaviconForHelmet'
import FileItem from '../FileItem/FileItem'
import { OnSelectAll } from '../FileItem/oncheckAction'
import QueryError from '../QueryError/QueryError'
const { Button, Heading, Spinner, Checkbox } = require('evergreen-ui')

interface IProps {
  fileStatus?: FilterStatus | undefined
  bankStatus?: FilterStatus | undefined
  bankCode?: string
  clientId?: string
  subtype?: string
  taskId?: string
  stashboardJobId?: string
  parentUserId?: string
  fileContains?: string
  shown?: boolean
  downloadType?: string | undefined
}

interface IState {
  isLoading: boolean
  checked: boolean
}

class AllFiles extends React.Component<any, IState> {
  public static defaultProps = {
    fileStatus: undefined,
    bankStatus: undefined,
    bankCode: undefined,
    clientId: undefined,
    subtype: undefined,
    taskId: undefined,
    stashboardJobId: undefined,
    parentUserId: undefined,
    fileContains: undefined,
    shown: false,
    downloadType: undefined,
  }

  public state = {
    isLoading: false,
    checked: false,
  }
  private addBulkData(data: any, e: any) {
    if (e.target.checked) {
      this.setState({ checked: true })
      const _data: any = []
      data.map((_file: any) => {
        _data.push(_file.node)
      })
      this.props.OnSelectAll(_data)
    } else {
      this.setState({ checked: false })
      this.props.OnSelectAll([])
    }
  }
  public render() {
    const { fileStatus, bankStatus } = this.props
    const {
      bankCode,
      clientId,
      fileContains,
      subtype,
      taskId,
      stashboardJobId,
      parentUserId,
    } = this.props

    return (
      <Query
        query={GET_ALL_FILES}
        variables={{
          fileStatus,
          bankStatus,
          bankCode,
          clientId,
          fileContains,
          subtype,
          taskId,
          stashboardJobId,
          parentUserId,
        }}
        fetchPolicy={'cache-and-network'}
      >
        {({ loading, error, data, refetch, fetchMore }) => {
          if (error) {
            return <QueryError error={error} />
          }
          if (loading) {
            return (
              <Loader>
                <Spinner marginX="auto" marginY={120} size={72} />
              </Loader>
            )
          }
          if (!data) {
            return (
              <div>
                <pre>{<code>{JSON.stringify(error, null, 2)}</code>}</pre>
              </div>
            )
          } else {
            const { allFiles } = data
            return this.renderMainComponent(
              allFiles,
              fetchMore,
              fileStatus,
              bankStatus,
              bankCode,
              clientId,
              fileContains,
              subtype,
              taskId,
              stashboardJobId,
              parentUserId,
              refetch,
            )
          }
        }}
      </Query>
    )
  }

  public renderMainComponent(
    allFiles: any,
    fetchMore: any,
    fileStatus: FilterStatus | undefined,
    bankStatus: FilterStatus | undefined,
    bankCode: string | undefined,
    clientId: string | undefined,
    fileContains: string | undefined,
    subtype: string | undefined,
    taskId: string | undefined,
    stashboardJobId: string | undefined,
    parentUserId: string | undefined,
    refetch: any,
  ) {
    if (allFiles && allFiles.edges && allFiles.edges.length === 0) {
      return <span>No files found</span>
    }
    return (
      <>
        <Helmet link={generateGenericFavicon()}>
          <title>{generateAllFilesTitle(this.props)}</title>
        </Helmet>
        <div style={{ padding: '18px', background: '#fff', height: '20px' }}>
          <Checkbox
            checked={this.state.checked}
            label="Select All"
            onChange={() => this.addBulkData(allFiles.edges, event)}
          />
        </div>
        {allFiles.edges.map(({ node }: GetAllFiles_allFiles_edges) => {
          return node && <FileItem selectAll={this.state.checked} key={node.id} {...node} />
        })}
        {allFiles.pageInfo.hasNextPage && (
          <LoadMoreButton
            isLoading={this.state.isLoading}
            appearance="primary"
            onClick={() =>
              fetchMore({
                variables: {
                  cursor: allFiles.pageInfo.endCursor,
                  fileStatus,
                  bankStatus,
                  bankCode,
                  clientId,
                  fileContains,
                  subtype,
                  taskId,
                  stashboardJobId,
                  parentUserId,
                },
                updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
                  const newEdges = fetchMoreResult.allFiles.edges
                  const pageInfo = fetchMoreResult.allFiles.pageInfo

                  return newEdges.length
                    ? {
                        allFiles: {
                          __typename: previousResult.allFiles.__typename,
                          edges: [...previousResult.allFiles.edges, ...newEdges],
                          pageInfo,
                        },
                      }
                    : previousResult
                },
              })
            }
          >
            Load more...
          </LoadMoreButton>
        )}
        <Container>
          <Heading is={'h1'} size={600} color={'#116AB8'} onClick={() => refetch()}>
            Refresh Jobs
          </Heading>
        </Container>
      </>
    )
  }
}

const GET_ALL_FILES = gql`
  query GetAllFiles(
    $fileStatus: String
    $bankStatus: String
    $cursor: String
    $bankCode: String
    $clientId: String
    $fileContains: String
    $subtype: String
    $taskId: String
    $stashboardJobId: String
    $parentUserId: String
  ) {
    allFiles(
      first: 30
      parsingStatus: $fileStatus
      bankStatus: $bankStatus
      after: $cursor
      bankCode: $bankCode
      subtype: $subtype
      taskId: $taskId
      stashboardJobId: $stashboardJobId
      parentUserId: $parentUserId
      mUser_UserId: $clientId
      inputFile: $fileContains
      orderBy: "-updated_at"
    ) {
      edges {
        node {
          id
          inputFile
          fileUuid
          parsingStatus
          conversionStatus
          originalFile
          taskId
          stashboardJobId
          parentUserId
          bankStatus
          bankPredictedBy
          params
          subtype
          mUser {
            id
            userId
          }
          updatedAt
          bankCode
          pageRange
          outputExcel
          canopyUlExcel
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const generateAllFilesTitle = ({ clientId, bankCode, fileContains }: IProps): string => {
  const customTitle = clientId || bankCode || fileContains
  return customTitle ? customTitle.toUpperCase() : 'Canopy Parser'
}

const LoadMoreButton = styled(Button)`
  width: calc(100% + 48px);
  flex-grow: 1;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  margin: 24px 0 -24px -24px;
`
const Container = styled.div`
  z-index: 9;

  position: fixed;
  top: 80px;
  left: 4.2rem;
  cursor: pointer;
`
const Loader = styled.div``
const mapDispatch = (dispatch: any) => {
  return bindActionCreators(
    {
      OnSelectAll,
    },
    dispatch,
  )
}
export default connect(null, mapDispatch)(AllFiles)
